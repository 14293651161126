export const LANDING_TRANSLATIONS_FR = {
    "landing": {
        "hero": {
            "title": "Étiquettes numériques pour votre vin",
            "subtitle": "Simples, précises et <span class=\"font-bold italic\">gratuites à jamais*</span>",
            "description": "Répondez aux obligations légales sans frais supplémentaires ni perte de temps. Grâce à notre plateforme, vous pouvez créer en quelques instants toutes les étiquettes numériques dont vous avez besoin",
            "cta": "Commencez dès maintenant"
        },
        "banner": {
            "text1": "NE PERDEZ PAS DE TEMPS !",
            "text2": "Offre valable uniquement pour les 100 premiers inscrits !"
        },
        "features": {
            "title": "Pourquoi nos étiquettes numériques sont différentes",
            "cards": {
                "easy": {
                    "number": "1",
                    "title": "Faciles",
                    "description": "Créez une étiquette en quelques instants grâce à l'interface la plus rapide du marché"
                },
                "personal": {
                    "number": "2",
                    "title": "Personnalisées",
                    "description": "Choisissez ce que vous souhaitez afficher avec vos couleurs et votre marque, rendez l'expérience de votre client unique"
                },
                "connected": {
                    "number": "3",
                    "title": "Connectées",
                    "description": "Suivez vos lots avec un seul QR, embouteillez plusieurs fois sans réimprimer les étiquettes"
                }
            },
            "freeForever": {
                "title": "Gratuites à jamais",
                "subtitle": "Mettez à jour vos étiquettes et la plateforme sera gratuite à jamais",
                "disclaimer": "(consultez les conditions dans la section FAQ)"
            }
        },
        "buildLabel": {
            "title": "Créez votre étiquette",
            "subtitle": "en quelques étapes",
            "description": "Enfin, en quelques clics, vous pouvez créer une étiquette complète. Sélectionnez les ingrédients et les matériaux dans nos bibliothèques, en évitant les saisies manuelles fastidieuses, et créez vos modèles pour accélérer les prochaines étiquettes",
            "cards": {
                "recycle": "Recyclage",
                "nutritional": "Valeurs nutritionnelles",
                "ingredients": "Ingrédients",
                "grapes": "Assemblage"
            }
        },
        "customize": {
            "title": "Personnalisez vos étiquettes",
            "subtitle": "facilement",
            "description": "Rendre votre marque reconnaissable et offrir à vos clients une expérience unique. Notre service vous permet de personnaliser non seulement les QR codes, mais aussi le style de votre page d'accueil"
        },
        "printCosts": {
            "title": "Réduisez les coûts d'impression,<br />accélérez les délais et <br />obtenez vos étiquettes immédiatement",
            "description": "Imprimez une seule étiquette pour toute l'année. <br>Utilisez un seul QR code, même pour les lots ayant des ingrédients ou des caractéristiques différents. Notre solution vous permet de réduire les coûts d'impression"
        },
        "scanQr": {
            "title1": "<span class=\"text-white text-4xl font-extrabold\">Scannez le </span><span class=\"text-ruby text-4xl font-extrabold\">QR <br /> </span><span class=\"text-white text-4xl font-extrabold\"> et découvrez l' </span><div class=\"bg-ruby -skew-x-12 inline-block px-2\">	<span class=\"text-white text-4xl font-extrabold\"> étiquette </span></div>",
            "description": "Découvrez un exemple de la manière dont vous pouvez répondre aux obligations légales et vous démarquer de vos concurrents en même temps"
        },
        "avoidFines": {
            "title": "Évitez les pénalités",
            "description": "Gardez vos étiquettes toujours à jour conformément à la réglementation et évitez les pénalités de 5 000 € à 50 000 €",
            "cta": {
                "mobile": "Commencez dès maintenant ->",
                "desktop": "Ne perdez pas de temps, commencez dès maintenant gratuitement ->"
            }
        },
        "features2": {
            "title": "Découvrez toutes les fonctionnalités de notre produit<br />agile et flexible</div>",
            "multilanguage": {
                "title": "Multilingue",
                "description": "Traduisez automatiquement dans toutes les langues européennes avec l'IA d'Albicchiere"
            },
            "calculator": {
                "title": "Calculateur automatique des <br />valeurs nutritionnelles",
                "description": "Utilisez les valeurs nutritionnelles précalculées ou saisissez manuellement vos données de laboratoire"
            },
            "updated": {
                "title": "Vos vins<br />toujours à jour",
                "description": "Notre plateforme apprend à connaître vos vins. <br> Pour chaque nouveau millésime, elle vous aide à remplir les fiches vin, ce qui rend votre travail toujours plus facile et plus rapide"
            },
            "additional": {
                "sharable": {
                    "title": "Partageable",
                    "description": "En un clic, le consommateur final partage immédiatement l'étiquette avec d'autres utilisateurs"
                },
                "batch": {
                    "title": "Variante de lot",
                    "description": "De nouveaux lots produits ? Décidez de conserver le même QR code ou d'en créer un nouveau"
                }
            }
        },
        "tracking": {
            "title": "Nous sommes prêts pour l'avenir, commencez à suivre vos lots réels",
            "subtitle": "Restez connecté <br />à vos lots à jamais,<br />jusqu'au domicile du client",
            "description": "Suivez vos bouteilles de votre cave jusqu'au domicile du consommateur final grâce à la technologie de traçabilité d'Albicchiere. <br /> <br /> Découvrez ce qui s'est passé et où se trouve chaque bouteille de votre lot. Protégez-vous contre les fraudes et les défauts générés par des tiers grâce à la plateforme de traçabilité la plus complète du marché"
        },
        "bottler": {
            "title": "Êtes-vous un étiqueteur ou un embouteilleur ?",
            "description": "Gérez plusieurs marques en même temps. Contactez-nous pour devenir partenaire et bénéficier de tous les avantages de notre service",
            "cta": "Planifiez un appel ->"
        },
        "faq": {
            "title": "FAQ",
            "subtitle": "Consultez les questions",
            "tabs": {
                "why": "Pourquoi l'utiliser",
                "conditions": "Conditions d'utilisation"
            },
            "why": {
                "q1": {
                    "question": "Quand le règlement UE est-il entré en vigueur ?",
                    "answer": "Le règlement UE est entré en vigueur le 8 décembre 2023, par conséquent, tous les vins produits à partir de cette date doivent être munis d'une étiquette numérique"
                },
                "q2": {
                    "question": "Puis-je mettre à jour mes étiquettes ?",
                    "answer": "Bien sûr, vous pouvez mettre à jour vos étiquettes quand vous le souhaitez. Vous pouvez modifier les ingrédients, les formats de recyclage ou le style du QR code quand vous le souhaitez : la modification sera mise en ligne immédiatement"
                },
                "q3": {
                    "question": "À quels vins la réglementation s'applique-t-elle ?",
                    "answer": "La réglementation s'applique aux vins tranquilles, aux vins mousseux, aux vins pétillants, au vin chaud et aux vins liquoreux"
                },
                "q4": {
                    "question": "Comment puis-je créer l'étiquette si je ne connais pas toutes les valeurs nutritionnelles du tableau ?",
                    "answer": "Notre calculateur automatique vous aidera à calculer les kilocalories pour 100 ML et d'autres données, il vous suffit de saisir le volume alcoolique, le sucre résiduel et l'acide tartrique"
                },
                "q5": {
                    "question": "Puis-je ajouter un lot sans réimprimer l'étiquette ?",
                    "answer": "Oui, notre service vous permet de créer un seul QR code et de le rediriger vers le lot souhaité. De cette façon, vous pouvez créer de nouveaux lots et les relier au même millésime sans avoir à imprimer de nouvelles étiquettes"
                }
            },
            "conditions": {
                "q1": {
                    "question": "Comment peut-elle être gratuite à jamais ?",
                    "answer": "Nous aimons offrir des services aux caves, notre objectif est d'avoir des fiches vin toujours à jour, c'est pourquoi nous offrons des services gratuits à ceux qui nous aident à les maintenir et à les aligner au fil des ans"
                },
                "q2": {
                    "question": "Puis-je charger toutes les étiquettes que je souhaite ?",
                    "answer": "Plus vous chargez d'étiquettes, plus nous sommes heureux, l'important est que les données soient correctes et complètes. Le service a une limite de trafic de données gratuite annuelle, mais ne vous inquiétez pas, car elle est largement suffisante pour toutes les visualisations qu'une petite ou moyenne cave reçoit. Si vous êtes une grande cave ou si vous gérez un grand nombre de produits différents, planifiez un appel et nous trouverons ensemble la solution qui répond le mieux à vos besoins."
                },
                "q3": {
                    "question": "Que se passe-t-il si je n'utilise plus votre service pour les nouveaux millésimes ?",
                    "answer": "Ne vous inquiétez pas, vous pouvez arrêter d'utiliser le service quand vous le souhaitez. Si vous ne souhaitez plus l'utiliser pour les nouveaux millésimes, vous pouvez décider de maintenir ou non les millésimes précédents actifs. Si vous décidez de maintenir le service actif, le coût sera de 49,90 €/an. Ah, vous pouvez reprendre le plan gratuit quand vous le souhaitez, il vous suffit de créer les nouvelles étiquettes de la dernière vendange au plus tard le 31 juillet de l'année suivante."
                },
                "q4": {
                    "question": "Comment utilisez-vous les données de mes étiquettes ?",
                    "answer": "Les données que vous saisissez seront uniquement visibles par les utilisateurs qui utilisent les services Albicchiere (comme ceux qui scannent le QR code de votre bouteille). Nous nous engageons à ce qu'aucune donnée ne soit cédée ou utilisée par des tiers sans votre autorisation."
                }
            }
        },
        "final": {
            "title": "Gratuites, à jamais",
            "description": "Commencez dès maintenant à créer vos étiquettes numériques, enfin : simples, précises et gratuites",
            "cta": "Commencez dès maintenant, gratuitement ->"
        },
        "footer": {
            "copyright": "© 2024 Beexlab Srl - P.IVA IT03557430547 - <a href=\"https://albicchiere.com/privacy-policy/\"> Politique de confidentialité</a>"
        }
    }
}