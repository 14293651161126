// Lithuanian
export const PAGE_TRANSLATIONS_LT = {
    page: {
        lot_selector: {
            title: 'Partijos kodas',
            description: `Įveskite partijos kodą, kurį norite rodyti šiam vynui: Partijos numerį dažniausiai
                             randate ant butelio nugarėlės. Būkite atsargūs, nes kiekviena partija gali turėti skirtingas 
                             sudedamąsias dalis ir maistinę vertę.`,
        },
        wine_color: {
            red: 'Raudonas',
            white: 'Baltas',
            rose: 'Rožinis',
        },
        wine_info: {
            color: 'Spalva',
            grapes: 'Vynuogės',
            region: 'Regionas',
            alcohol: 'Alkoholio kiekis',
            service_temp: 'Patiekimo temperatūra',
            size: 'Dydis',
        },
        ingredients: {
            title: 'Sudėtis',
            text: 'Pagrindinės sudedamosios dalys: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TIPAS',
            for_quantity: '{{quantity}}ml ',
            energy: 'Energija',
            fat: 'Riebalai',
            saturated_fat: 'iš kurių sočiųjų',
            carbs: 'Angliavandeniai',
            sugar_carbs: 'iš kurių cukrų',
            protein: 'Baltymai',
            salt: 'Natris',
        },
        recycle: 'Perdirbimas',
        warning: 'Įspėjimai',
        certification: 'Sertifikavimas',
        production_info: 'Informacija apie gamybą',
        operator_type: {
            producer: 'Gamintojas',
            bottler: 'Buteliuotojas',
            importer: 'Importuotojas',
        },
        legal_details: 'Teisinė informacija'
    }
}