export const PAGE_TRANSLATIONS_IT = {
    page: {
        lot_selector: {
            title: 'Codice Lotto',
            description: `Inserisci il codice lotto che vuoi visualizzare per questo vino: Il numero lotto si trova solitamente
                            sul retro della tua bottiglia. Fai attenzione perché ogni lotto può avere ingredienti e valori 
                            nutrizionali diversi.`,
        },
        wine_color: {
            red: 'Rosso',
            white: 'Bianco',
            rose: 'Rosé',
        },
        wine_info: {
            color: 'Colore',
            grapes: 'Uvaggio',
            region: 'Regione',
            alcohol: 'Alcohol volume',
            service_temp: 'Temperature di servizio',
            size: 'Quantità',
        },
        ingredients: {
            title: 'Ingredienti',
            text: 'Ingredienti principali: {{ingredients}} ',
            categories: {
                allergens: 'Allergeni',
                smallQuantities: 'Contiene piccole quantità di',
            }
        },
        nutritional_facts: {
            type: 'Tipo',
            for_quantity: 'Per {{quantity}}ml ',
            energy: 'Energia',
            fat: 'Grassi',
            saturated_fat: 'di cui saturi',
            carbs: 'Carboidrati',
            sugar_carbs: 'di cui zuccheri',
            protein: 'Proteine',
            salt: 'Sale',
        },
        recycle: 'Riciclo',
        warning: 'Consumo responsabile',
        certification: 'Certificazioni di sostenibilità',
        production_info: 'Informazioni di produzione',
        operator_type: {
            producer: 'Produttore',
            bottler: 'Imbottigliatore',
            importer: 'Importatore',
        },
        legal_details: 'Avvisi di legge'
    }
}