export const LANDING_TRANSLATIONS_PT = {
    "landing": {
        "hero": {
            "title": "Etiquetas digitais para o seu vinho",
            "subtitle": "Simples, precisas e <span class=\"font-bold italic\">grátis para sempre*</span>",
            "description": "Cumprir com as obrigações legais sem custos adicionais ou perda de tempo. Com nossa plataforma, você pode criar em instantes todas as etiquetas digitais de que precisa",
            "cta": "Comece agora"
        },
        "banner": {
            "text1": "NÃO PERCA TEMPO!",
            "text2": "Oferta válida apenas para os primeiros 100 inscritos!"
        },
        "features": {
            "title": "Por que nossas etiquetas digitais são diferentes",
            "cards": {
                "easy": {
                    "number": "1",
                    "title": "Fáciles",
                    "description": "Crie uma etiqueta em instantes com a interface mais rápida do mercado"
                },
                "personal": {
                    "number": "2",
                    "title": "Personalizadas",
                    "description": "Escolha o que mostrar com suas cores e sua marca, torne a experiência do seu cliente única"
                },
                "connected": {
                    "number": "3",
                    "title": "Conectadas",
                    "description": "Rastrie seus lotes com um único QR, engarrafamento várias vezes sem precisar reimprimir as etiquetas"
                }
            },
            "freeForever": {
                "title": "Gratuitas para sempre",
                "subtitle": "Mantenha suas etiquetas atualizadas e a plataforma será gratuita para sempre",
                "disclaimer": "(leia as condições na seção de perguntas frequentes)"
            }
        },
        "buildLabel": {
            "title": "Crie seu rótulo",
            "subtitle": "em poucas etapas",
            "description": "Finalmente, com apenas alguns cliques, você pode criar um rótulo completo. Selecione os ingredientes e materiais em nossas bibliotecas, evitando entradas manuais tediosas, e crie seus modelos para acelerar os rótulos futuros",
            "cards": {
                "recycle": "Reciclagem",
                "nutritional": "Valores nutricionais",
                "ingredients": "Ingredientes",
                "grapes": "Mistura"
            }
        },
        "customize": {
            "title": "Personalize seus rótulos",
            "subtitle": "facilmente",
            "description": "Faça sua marca ser reconhecível e ofereça aos seus clientes uma experiência única. Nosso serviço permite personalizar não apenas os QRs, mas também o estilo da sua página inicial"
        },
        "printCosts": {
            "title": "Reduza os custos de impressão,<br />acelere os tempos e <br />obtenha seus rótulos imediatamente",
            "description": "Imprima apenas um rótulo para o ano inteiro. <br>Use apenas um QR, mesmo para lotes com ingredientes ou características diferentes. Nossa solução permite reduzir os custos de impressão"
        },
        "scanQr": {
            "title1": "<span class=\"text-white text-4xl font-extrabold\">Digitalize o </span><span class=\"text-ruby text-4xl font-extrabold\">QR <br /> </span><span class=\"text-white text-4xl font-extrabold\"> e descubra a </span><div class=\"bg-ruby -skew-x-12 inline-block px-2\">	<span class=\"text-white text-4xl font-extrabold\"> etiqueta </span></div>",
            "description": "Veja um exemplo de como você pode cumprir com as obrigações legais e se diferenciar dos seus concorrentes ao mesmo tempo"
        },
        "avoidFines": {
            "title": "Evite as multas",
            "description": "Mantenha seus rótulos sempre atualizados com a legislação e evite multas de € 5.000 a € 50.000",
            "cta": {
                "mobile": "Comece agora ->",
                "desktop": "Não perca tempo, comece agora gratuitamente ->"
            }
        },
        "features2": {
            "title": "Descubra todos os recursos do nosso produto<br />ágil e flexível</div>",
            "multilanguage": {
                "title": "Multilíngue",
                "description": "Traduza automaticamente para todos os idiomas europeus com a IA do Albicchiere"
            },
            "calculator": {
                "title": "Calculadora automática de <br />valores nutricionais",
                "description": "Use os valores nutricionais pré-calculados ou insira seus dados de laboratório manualmente"
            },
            "updated": {
                "title": "Seus vinhos<br />sempre atualizados",
                "description": "Nossa plataforma aprende a conhecer seus vinhos. <br> Para cada nova safra, ela o ajuda a preencher as fichas de vinho, tornando seu trabalho cada vez mais fácil e rápido"
            },
            "additional": {
                "sharable": {
                    "title": "Compartilhável",
                    "description": "Com um clique, o consumidor final compartilha imediatamente o rótulo com outros usuários"
                },
                "batch": {
                    "title": "Variação de lote",
                    "description": "Novos lotes produzidos? Decida se mantém o mesmo QR ou cria um novo"
                }
            }
        },
        "tracking": {
            "title": "Estamos prontos para o futuro, comece a rastrear seus lotes reais",
            "subtitle": "Mantenha-se conectado <br />aos seus lotes para sempre,<br />até a casa do cliente",
            "description": "Acompanhe suas garrafas desde sua vinícola até a casa do consumidor final com a tecnologia de rastreabilidade do Albicchiere. <br /> <br /> Descubra o que aconteceu e onde está cada garrafa do seu lote. Proteja-se contra fraudes e defeitos gerados por terceiros com a plataforma de rastreabilidade mais completa do mercado"
        },
        "bottler": {
            "title": "Você é um etiquetador ou engarrafador?",
            "description": "Gerencie várias marcas ao mesmo tempo. Entre em contato conosco para se tornar parceiro e obter todos os benefícios do nosso serviço",
            "cta": "Agende uma chamada ->"
        },
        "faq": {
            "title": "Perguntas frequentes",
            "subtitle": "Consulte as perguntas",
            "tabs": {
                "why": "Por que usá-lo",
                "conditions": "Condições de uso"
            },
            "why": {
                "q1": {
                    "question": "Quando o regulamento da UE entrou em vigor?",
                    "answer": "O regulamento da UE entrou em vigor em 8 de dezembro de 2023, portanto, todos os vinhos produzidos a partir dessa data devem ter um rótulo digital"
                },
                "q2": {
                    "question": "Posso atualizar meus rótulos?",
                    "answer": "Claro, você pode atualizar seus rótulos quando quiser. Você pode alterar os ingredientes, os formatos de reciclagem ou o estilo do QR quando quiser: a alteração será publicada online imediatamente"
                },
                "q3": {
                    "question": "A quais vinhos a legislação se aplica?",
                    "answer": "A legislação afeta os vinhos tranquilos, espumantes, frizantes, vinhos quentes e vinhos licorosos"
                },
                "q4": {
                    "question": "Como posso criar o rótulo se não conheço todos os valores nutricionais da tabela?",
                    "answer": "Nossa calculadora automática o ajudará a calcular as calorias por 100 ml e outros dados, basta inserir o volume de álcool, o açúcar residual e o ácido tartárico"
                },
                "q5": {
                    "question": "Posso adicionar um lote sem reimprimir o rótulo?",
                    "answer": "Sim, nosso serviço permite criar um único QR e depois redirecioná-lo para o lote desejado. Dessa forma, você pode criar novos lotes e vinculá-los à mesma safra sem ter que imprimir novos rótulos"
                }
            },
            "conditions": {
                "q1": {
                    "question": "Como pode ser gratuito para sempre?",
                    "answer": "Adoramos oferecer serviços às vinícolas, nosso objetivo é ter fichas de vinho sempre atualizadas, por isso oferecemos serviços gratuitos para aqueles que nos ajudam a mantê-las e alinhá-las ao longo dos anos"
                },
                "q2": {
                    "question": "Posso carregar todos os rótulos que quiser?",
                    "answer": "Quanto mais rótulos você carregar, mais felizes ficaremos, o importante é que os dados estejam corretos e completos. O serviço tem um limite de tráfego de dados gratuito anual, mas não se preocupe, pois é mais do que suficiente para todas as visualizações que uma pequena ou média vinícola recebe. Se você é uma grande vinícola ou gerencia um grande número de produtos diferentes, agende uma chamada e encontraremos juntos a solução que melhor se adapta às suas necessidades."
                },
                "q3": {
                    "question": "O que acontece se eu não usar mais seu serviço para novas safras?",
                    "answer": "Não se preocupe, você pode parar de usar o serviço quando quiser. Se você não quiser mais usá-lo para novas safras, poderá decidir se mantém as safras anteriores ativas ou não. Se você decidir manter o serviço ativo, o custo será de € 49,90 / ano. Ah, você pode voltar ao plano gratuito quando quiser, basta criar as novas etiquetas da última colheita até 31 de julho do ano seguinte."
                },
                "q4": {
                    "question": "Como você usa os dados dos meus rótulos?",
                    "answer": "Os dados que você inserir serão visíveis apenas para os usuários que usam os serviços do Albicchiere (como aqueles que digitalizam o código QR da sua garrafa). Nos comprometemos a garantir que nenhum dado seja transferido ou utilizado por terceiros sem sua autorização."
                }
            }
        },
        "final": {
            "title": "Gratuitas, para sempre",
            "description": "Comece agora a criar suas etiquetas digitais, finalmente: simples, precisas e gratuitas",
            "cta": "Comece agora, grátis ->"
        },
        "footer": {
            "copyright": "© 2024 Beexlab Srl - P.IVA IT03557430547 - <a href=\"https://albicchiere.com/privacy-policy/\"> Política de privacidade</a>"
        }
    }
}