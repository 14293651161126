// Maltese
export const PAGE_TRANSLATIONS_MT = {
    page: {
        lot_selector: {
            title: 'Kodiċ tal-lott',
            description: `Daħħal il-kodiċi tal-lott li trid turi għal din il-inbid: In-numru tal-lott jinstab ġeneralment
                             wara l-flixkun tiegħek. Oqgħod attent għax kull lott jista 'jkollha ingredjenti 
                             u valuri nutrittivi differenti.`,
        },
        wine_color: {
            red: 'Aħmar',
            white: 'Abjad',
            rose: 'Roża',
        },
        wine_info: {
            color: 'Kulur',
            grapes: 'Għeneb',
            region: 'Reġjun',
            alcohol: 'Volum tal-Alkoħol',
            service_temp: 'Temperatura tas-Servizz',
            size: 'Daqs',
        },
        ingredients: {
            title: 'Ingredjenti',
            text: 'Ingredjenti primarji: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TIP',
            for_quantity: 'Għal {{quantity}}ml ',
            energy: 'Enerġija',
            fat: 'Xaħam',
            saturated_fat: 'minn dawn saturati',
            carbs: 'Karboidrati',
            sugar_carbs: 'minn dawn zokkor',
            protein: 'Proteina',
            salt: 'Sodju',
        },
        recycle: 'Riċiklaġġ',
        warning: 'Twissijiet',
        certification: 'Ċertifikazzjoni',
        production_info: 'Informazzjoni dwar il-Produzzjoni',
        operator_type: {
            producer: 'Produttur',
            bottler: 'Imbottigliatur',
            importer: 'Importatur',
        },
        legal_details: 'Dettalji Legali'
    }
}