// Irish
export const PAGE_TRANSLATIONS_GA = {
    page: {
        lot_selector: {
            title: 'Cód Baits',
            description: `Cuir isteach an cód baits is mian leat a thaispeáint don fíon seo: Is féidir an uimhir baits a fháil de ghnáth
                             ar chúl do bhuidéil. Bí cúramach mar d’fhéadfadh go mbeadh comhábhair agus luachanna cothaitheacha éagsúla ag gach baits.`,
        },
        wine_color: {
            red: 'Dearg',
            white: 'Bán',
            rose: 'Rosé',
        },
        wine_info: {
            color: 'Dath',
            grapes: 'Fíonchaora',
            region: 'Réigiún',
            alcohol: 'Méid Alcóil',
            service_temp: 'Teocht Sheirbheáil',
            size: 'Méid',
        },
        ingredients: {
            title: 'Comhábhair',
            text: 'Príomhchomhábhair: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'CINEÁL',
            for_quantity: 'Do {{quantity}}ml ',
            energy: 'Fuinneamh',
            fat: 'Saill',
            saturated_fat: 'díobh sin sáithithe',
            carbs: 'Carbaihiodráit',
            sugar_carbs: 'díobh sin siúcraí',
            protein: 'Próitéin',
            salt: 'Sóidiam',
        },
        recycle: 'Athshlánú',
        warning: 'Rabhadh',
        certification: 'Deimhniú',
        production_info: 'Eolas Táirgthe',
        operator_type: {
            producer: 'Táirgeoir',
            bottler: 'Buidéalaí',
            importer: 'Allmhaireoir',
        },
        legal_details: 'Sonraí Dlíthiúla'
    }
}