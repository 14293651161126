// Estonian
export const PAGE_TRANSLATIONS_ET = {
    page: {
        lot_selector: {
            title: 'Partii kood',
            description: `Sisestage partii kood, mida soovite selle veini puhul kuvada: Partii number on tavaliselt
                             pudeli tagaküljel. Olge ettevaatlik, sest igal partiil võivad olla erinevad 
                             koostisosad ja toiteväärtused.`,
        },
        wine_color: {
            red: 'Punane',
            white: 'Valge',
            rose: 'Rosé',
        },
        wine_info: {
            color: 'Värv',
            grapes: 'Viinamarjasort',
            region: 'Piirkond',
            alcohol: 'Alkoholmaht',
            service_temp: 'Serveerimistemperatuur',
            size: 'Suurus',
        },
        ingredients: {
            title: 'Koostisosad',
            text: 'Peamised koostisosad: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TÜÜP',
            for_quantity: '{{quantity}}ml kohta ',
            energy: 'Energia',
            fat: 'Rass',
            saturated_fat: 'millest küllastunud',
            carbs: 'Süsivesikud',
            sugar_carbs: 'millest suhkrud',
            protein: 'Valgud',
            salt: 'Sool',
        },
        recycle: 'Ringlussevõtt',
        warning: 'Hoiatused',
        certification: 'Sertifitseerimine',
        production_info: 'Tootmisinfo',
        operator_type: {
            producer: 'Tootja',
            bottler: 'Pudelitaja',
            importer: 'Importija',
        },
        legal_details: 'Juriidilised andmed'
    }
}