// Swedish
export const PAGE_TRANSLATIONS_SV = {
    page: {
        lot_selector: {
            title: 'Batchkod',
            description: `Ange batchkoden du vill visa för detta vin: Batchnumret finns vanligtvis på baksidan av flaskan. Var försiktig, eftersom varje batch kan ha olika ingredienser och näringsvärden.`,
        },
        wine_color: {
            red: 'Rött',
            white: 'Vitt',
            rose: 'Rosé',
        },
        wine_info: {
            color: 'Färg',
            grapes: 'Druvsort',
            region: 'Region',
            alcohol: 'Alkoholhalt',
            service_temp: 'Serveringstemperatur',
            size: 'Storlek',
        },
        ingredients: {
            title: 'Ingredienser',
            text: 'Huvudingredienser: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TYP',
            for_quantity: 'För {{quantity}}ml ',
            energy: 'Energi',
            fat: 'Fett',
            saturated_fat: 'varav mättat',
            carbs: 'Kolhydrater',
            sugar_carbs: 'varav sockerarter',
            protein: 'Protein',
            salt: 'Natrium',
        },
        recycle: 'Återvinning',
        warning: 'Varningar',
        certification: 'Certifiering',
        production_info: 'Produktionsinformation',
        operator_type: {
            producer: 'Producent',
            bottler: 'Fyllare',
            importer: 'Importör',
        },
        legal_details: 'Juridisk information'
    }
}