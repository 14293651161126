export const LANDING_TRANSLATIONS_DE = {
    "landing": {
        "hero": {
            "title": "Digitale Etiketten für Ihren Wein",
            "subtitle": "Einfach, präzise und <span class=\"font-bold italic\">für immer kostenlos*</span>",
            "description": "Erfüllen Sie die gesetzlichen Verpflichtungen ohne zusätzliche Kosten und Zeitverlust. Mit unserer Plattform können Sie in Sekundenschnelle alle digitalen Etiketten erstellen, die Sie benötigen",
            "cta": "Jetzt starten"
        },
        "banner": {
            "text1": "VERLIEREN SIE KEINE ZEIT!",
            "text2": "Angebot gültig nur für die ersten 100 Abonnenten!"
        },
        "features": {
            "title": "Warum unsere digitalen Etiketten anders sind",
            "cards": {
                "easy": {
                    "number": "1",
                    "title": "Einfach",
                    "description": "Erstellen Sie eine Etikette in Sekundenschnelle mit der schnellsten Oberfläche auf dem Markt"
                },
                "personal": {
                    "number": "2",
                    "title": "Persönlich",
                    "description": "Wählen Sie, was Sie mit Ihren Farben und Ihrer Marke zeigen möchten, machen Sie die Erfahrung Ihres Kunden einzigartig"
                },
                "connected": {
                    "number": "3",
                    "title": "Vernetzt",
                    "description": "Verfolgen Sie Ihre Chargen mit einem einzigen QR-Code, abfüllen Sie mehrmals, ohne die Etiketten neu zu drucken"
                }
            },
            "freeForever": {
                "title": "Für immer kostenlos",
                "subtitle": "Halten Sie Ihre Etiketten auf dem neuesten Stand und die Plattform bleibt für immer kostenlos",
                "disclaimer": "(lesen Sie die Bedingungen im FAQ-Bereich)"
            }
        },
        "buildLabel": {
            "title": "Erstellen Sie Ihr Etikett",
            "subtitle": "in wenigen Schritten",
            "description": "Endlich können Sie mit wenigen Klicks ein komplettes Etikett erstellen. Wählen Sie Zutaten und Materialien aus unseren Bibliotheken, vermeiden Sie langwierige manuelle Eingaben und erstellen Sie Ihre Vorlagen, um zukünftige Etiketten zu beschleunigen",
            "cards": {
                "recycle": "Recycling",
                "nutritional": "Nährwertangaben",
                "ingredients": "Zutaten",
                "grapes": "Rebsorten"
            }
        },
        "customize": {
            "title": "Personalisieren Sie Ihre Etiketten",
            "subtitle": "mit Leichtigkeit",
            "description": "Machen Sie Ihre Marke erkennbar und bieten Sie Ihren Kunden ein einzigartiges Erlebnis. Unser Service ermöglicht es Ihnen, nicht nur die QR-Codes, sondern auch den Stil Ihrer Landing Page zu personalisieren"
        },
        "printCosts": {
            "title": "Reduzieren Sie Ihre Druckkosten,<br />beschleunigen Sie die Zeiten und <br />erhalten Sie Ihre Etiketten sofort",
            "description": "Drucken Sie nur ein Etikett für das ganze Jahr. <br>Verwenden Sie einen einzigen QR-Code, auch für Chargen mit unterschiedlichen Zutaten oder Eigenschaften. Unsere Lösung ermöglicht es Ihnen, Druckkosten zu senken"
        },
        "scanQr": {
            "title1": "<span class=\"text-white text-4xl font-extrabold\">Scannen Sie den </span><span class=\"text-ruby text-4xl font-extrabold\">QR-Code <br /> </span><span class=\"text-white text-4xl font-extrabold\"> und entdecken Sie das </span><div class=\"bg-ruby -skew-x-12 inline-block px-2\">	<span class=\"text-white text-4xl font-extrabold\"> Etikett </span></div>",
            "description": "Sehen Sie sich ein Beispiel dafür an, wie Sie gleichzeitig die gesetzlichen Verpflichtungen erfüllen und sich von Ihren Mitbewerbern abheben können"
        },
        "avoidFines": {
            "title": "Vermeiden Sie Strafen",
            "description": "Halten Sie Ihre Etiketten stets auf dem neuesten Stand der Gesetzgebung und vermeiden Sie Strafen von 5.000 € bis 50.000 €",
            "cta": {
                "mobile": "Jetzt starten ->",
                "desktop": "Verschwenden Sie keine Zeit, starten Sie jetzt kostenlos ->"
            }
        },
        "features2": {
            "title": "Entdecken Sie alle Funktionen unseres Produkts<br />agil und flexibel</div>",
            "multilanguage": {
                "title": "Mehrsprachig",
                "description": "Übersetzen Sie automatisch in alle europäischen Sprachen mit der KI von Albicchiere"
            },
            "calculator": {
                "title": "Automatischer Rechner für <br />Nährwertangaben",
                "description": "Verwenden Sie die vorkalkulierten Nährwertangaben oder geben Sie Ihre Labordaten manuell ein"
            },
            "updated": {
                "title": "Ihre Weine<br />immer aktuell",
                "description": "Unsere Plattform lernt Ihre Weine kennen. <br> Für jeden neuen Jahrgang hilft sie Ihnen, die Weindatenblätter zu vervollständigen, was Ihre Arbeit immer einfacher und schneller macht"
            },
            "additional": {
                "sharable": {
                    "title": "Teilbar",
                    "description": "Mit einem Klick teilt der Endverbraucher das Etikett sofort mit anderen Nutzern"
                },
                "batch": {
                    "title": "Chargenvariante",
                    "description": "Neue Chargen produziert? Entscheiden Sie, ob Sie denselben QR-Code beibehalten oder einen neuen erstellen"
                }
            }
        },
        "tracking": {
            "title": "Wir sind bereit für die Zukunft, beginnen Sie mit der Verfolgung Ihrer echten Chargen",
            "subtitle": "Bleiben Sie <br />für immer mit Ihren Chargen verbunden,<br />bis zum Kunden nach Hause",
            "description": "Verfolgen Sie Ihre Flaschen von Ihrem Weingut bis zum Endkunden nach Hause dank der Traceability-Technologie von Albicchiere. <br /> <br /> Entdecken Sie, was passiert ist und wo sich jede Flasche Ihrer Charge befindet. Schützen Sie sich vor Betrug und Mängeln, die durch Dritte verursacht werden, mit der umfassendsten Traceability-Plattform auf dem Markt"
        },
        "bottler": {
            "title": "Sind Sie ein Etikettierer oder ein Abfüller?",
            "description": "Verwalten Sie mehrere Marken gleichzeitig. Kontaktieren Sie uns, um Partner zu werden und alle Vorteile unseres Dienstes zu nutzen",
            "cta": "Termin für ein Gespräch vereinbaren ->"
        },
        "faq": {
            "title": "FAQs",
            "subtitle": "Sehen Sie sich die Fragen an",
            "tabs": {
                "why": "Warum verwenden?",
                "conditions": "Nutzungsbedingungen"
            },
            "why": {
                "q1": {
                    "question": "Wann trat die EU-Verordnung in Kraft?",
                    "answer": "Die EU-Verordnung trat am 8. Dezember 2023 in Kraft, daher müssen alle Weine, die ab diesem Datum hergestellt werden, mit einem digitalen Etikett versehen sein"
                },
                "q2": {
                    "question": "Kann ich meine Etiketten aktualisieren?",
                    "answer": "Natürlich können Sie Ihre Etiketten jederzeit aktualisieren. Sie können Zutaten, Recyclingformate oder den QR-Code-Stil jederzeit ändern: Die Änderung wird sofort online gehen"
                },
                "q3": {
                    "question": "Auf welche Weine gilt die Verordnung?",
                    "answer": "Die Verordnung gilt für stille Weine, Schaumweine, Perlweine, Glühwein und Likörweine"
                },
                "q4": {
                    "question": "Wie kann ich das Etikett erstellen, wenn ich nicht alle Nährwerte der Tabelle kenne?",
                    "answer": "Unser automatischer Rechner hilft Ihnen, die Kilokalorien pro 100 ml und andere Daten zu berechnen, Sie müssen nur den Alkoholgehalt, den Restzucker und die Weinsäure eingeben"
                },
                "q5": {
                    "question": "Kann ich eine Charge hinzufügen, ohne das Etikett neu zu drucken?",
                    "answer": "Ja, unser Service ermöglicht es Ihnen, einen einzigen QR-Code zu erstellen und ihn dann auf die gewünschte Charge umzuleiten. So können Sie neue Chargen erstellen und sie mit derselben Jahrgangszahl verknüpfen, ohne neue Etiketten drucken zu müssen"
                }
            },
            "conditions": {
                "q1": {
                    "question": "Wie kann es für immer kostenlos sein?",
                    "answer": "Wir lieben es, Dienstleistungen für Weingüter anzubieten, unser Ziel ist es, Weindatenblätter stets auf dem neuesten Stand zu halten. Daher bieten wir kostenlose Dienstleistungen für diejenigen an, die uns helfen, diese zu pflegen und über die Jahre hinweg auszurichten"
                },
                "q2": {
                    "question": "Kann ich alle Etiketten hochladen, die ich möchte?",
                    "answer": "Je mehr Etiketten Sie hochladen, desto glücklicher sind wir, wichtig ist, dass die Daten korrekt und vollständig sind. Der Dienst hat ein jährliches Limit für kostenlosen Datenverkehr, aber keine Sorge, denn er ist mehr als ausreichend für alle Aufrufe, die ein kleines oder mittleres Weingut erhält. Wenn Sie ein großes Weingut sind oder eine große Anzahl verschiedener Produkte verwalten, vereinbaren Sie einen Termin für ein Gespräch und wir finden gemeinsam die Lösung, die am besten zu Ihren Bedürfnissen passt."
                },
                "q3": {
                    "question": "Was passiert, wenn ich Ihren Service nicht mehr für neue Jahrgänge verwende?",
                    "answer": "Keine Sorge, Sie können den Service jederzeit beenden. Wenn Sie ihn nicht mehr für neue Jahrgänge verwenden möchten, können Sie entscheiden, ob Sie die vorherigen Jahrgänge aktiv halten möchten oder nicht. Wenn Sie den Service aktiv halten möchten, fallen Kosten von 49,90 € pro Jahr an. Ah, Sie können jederzeit wieder zum kostenlosen Plan zurückkehren. Sie müssen nur die neuen Etiketten des letzten Jahrgangs bis zum 31. Juli des Folgejahres erstellen."
                },
                "q4": {
                    "question": "Wie verwenden Sie die Daten meiner Etiketten?",
                    "answer": "Die von Ihnen eingegebenen Daten sind nur für Benutzer sichtbar, die die Dienste von Albicchiere nutzen (z. B. diejenigen, die den QR-Code Ihrer Flasche scannen). Wir verpflichten uns, sicherzustellen, dass keine Daten ohne Ihre Genehmigung an Dritte weitergegeben oder von diesen verwendet werden."
                }
            }
        },
        "final": {
            "title": "Kostenlos, für immer",
            "description": "Beginnen Sie jetzt mit der Erstellung Ihrer digitalen Etiketten, endlich: einfach, präzise und kostenlos",
            "cta": "Jetzt kostenlos starten ->"
        },
        "footer": {
            "copyright": "© 2024 Beexlab Srl - P.IVA IT03557430547 - <a href=\"https://albicchiere.com/privacy-policy/\"> Datenschutzerklärung</a>"
        }
    }
}