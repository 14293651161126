// Hungarian
export const PAGE_TRANSLATIONS_HU = {
    page: {
        lot_selector: {
            title: 'Tételkód',
            description: `Adja meg a tételkódot, amelyet megjeleníteni szeretne ehhez a borhoz: A tételszám általában a palack hátulján található. Legyen óvatos, mivel minden tétel eltérő összetevőket és tápértéket tartalmazhat.`,
        },
        wine_color: {
            red: 'Vörös',
            white: 'Fehér',
            rose: 'Rosé',
        },
        wine_info: {
            color: 'Szín',
            grapes: 'Szőlő',
            region: 'Régió',
            alcohol: 'Alkoholmennyiség',
            service_temp: 'Szervírozási hőmérséklet',
            size: 'Méret',
        },
        ingredients: {
            title: 'Összetevők',
            text: 'Fő összetevők: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TÍPUS',
            for_quantity: '{{quantity}}ml-re ',
            energy: 'Energia',
            fat: 'Zsír',
            saturated_fat: 'ebből telített zsírsavak',
            carbs: 'Szénhidrát',
            sugar_carbs: 'ebből cukrok',
            protein: 'Fehérje',
            salt: 'Nátrium',
        },
        recycle: 'újrahasznosítás',
        warning: 'Figyelmeztetések',
        certification: 'Tanúsítás',
        production_info: 'Gyártási információk',
        operator_type: {
            producer: 'Termelő',
            bottler: 'Palackozó',
            importer: 'Importőr',
        },
        legal_details: 'Jogi információk'
    }
}