// Greek
export const PAGE_TRANSLATIONS_EL = {
    page: {
        lot_selector: {
            title: 'Κωδικός παρτίδας',
            description: `Εισαγάγετε τον κωδικό παρτίδας που θέλετε να εμφανίσετε για αυτό το κρασί: Ο αριθμός παρτίδας βρίσκεται συνήθως
                             στην πίσω πλευρά του μπουκαλιού σας. Να είστε προσεκτικοί, καθώς κάθε παρτίδα μπορεί να έχει διαφορετικά 
                             συστατικά και θρεπτικές αξίες.`,
        },
        wine_color: {
            red: 'Κόκκινο',
            white: 'Λευκό',
            rose: 'Ροζέ',
        },
        wine_info: {
            color: 'Χρώμα',
            grapes: 'Σταφύλια',
            region: 'Περιοχή',
            alcohol: 'Ποσοστό αλκοόλ',
            service_temp: 'Θερμοκρασία σερβιρίσματος',
            size: 'Μέγεθος',
        },
        ingredients: {
            title: 'Συστατικά',
            text: 'Κύρια συστατικά: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'ΤΥΠΟΣ',
            for_quantity: 'Για {{quantity}}ml ',
            energy: 'Ενέργεια',
            fat: 'Λίπος',
            saturated_fat: 'από τα οποία κορεσμένα',
            carbs: 'Υδατάνθρακες',
            sugar_carbs: 'από τα οποία σάκχαρη',
            protein: 'Πρωτεΐνη',
            salt: 'Νάτριο',
        },
        recycle: 'Ανακύκλωση',
        warning: 'Προειδοποιήσεις',
        certification: 'Πιστοποίηση',
        production_info: 'Πληροφορίες παραγωγής',
        operator_type: {
            producer: 'Παραγωγός',
            bottler: 'Συσκευαστής',
            importer: 'Εισαγωγέας',
        },
        legal_details: 'Νομικές λεπτομέρειες'
    }
}