// Latvian
export const PAGE_TRANSLATIONS_LV = {
    page: {
        lot_selector: {
            title: 'Partijas kods',
            description: `Ievadiet partijas kodu, kuru vēlaties parādīt šim vīnam: Partijas numurs parasti
                             atrodas uz pudeles aizmugures. Esiet uzmanīgi, jo katrai partijai var būt dažādi
                             sastāvdaļas un uzturvērtības.`,
        },
        wine_color: {
            red: 'Sarkanais',
            white: 'Baltais',
            rose: 'Rožains',
        },
        wine_info: {
            color: 'Krāsa',
            grapes: 'Vīnogas',
            region: 'Reģions',
            alcohol: 'Alkohola saturs',
            service_temp: 'Pasniegšanas temperatūra',
            size: 'Izmērs',
        },
        ingredients: {
            title: 'Sastāvdaļas',
            text: 'Galvenās sastāvdaļas: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TIPS',
            for_quantity: 'Uz {{quantity}}ml ',
            energy: 'Enerģija',
            fat: 'Tauki',
            saturated_fat: 'no kuriem piesātinātie',
            carbs: 'Ogļhidrāti',
            sugar_carbs: 'no kuriem cukuri',
            protein: 'Olbaltumvielas',
            salt: 'Nātrijs',
        },
        recycle: 'Pārstrāde',
        warning: 'Brīdinājumi',
        certification: 'Sertifikācija',
        production_info: 'Ražošanas informācija',
        operator_type: {
            producer: 'Ražotājs',
            bottler: 'Pudeļu pildītājs',
            importer: 'Importētājs',
        },
        legal_details: 'Juridiskā informācija'
    }
}