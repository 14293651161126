export const PAGE_TRANSLATIONS_EN = {
    page: {
        lot_selector: {
            title: 'Batch Code',
            description: `Enter the lot code you want to display for this wine: The lot number can usually
                             be found on the back of your bottle. Be careful as each batch may have different 
                             ingredients and nutritional values.`,
        },
        wine_color: {
            red: 'Red',
            white: 'White',
            rose: 'Rosé',
        },
        wine_info: {
            color: 'Color',
            grapes: 'Grapes',
            region: 'Region',
            alcohol: 'Alcohol volume',
            service_temp: 'Service temperature',
            size: 'Size',
        },
        ingredients: {
            title: 'Ingredients',
            text: 'Primary ingredients: {{ingredients}} ',
            categories: {
                allergens: 'Allergens',
                smallQuantities: 'Contains small quantiti of',
            }
        },
        nutritional_facts: {
            type: 'TYPE',
            for_quantity: 'For {{quantity}}ml ',
            energy: 'Energy',
            fat: 'Fat',
            saturated_fat: 'of which satures',
            carbs: 'Carbohydrate',
            sugar_carbs: 'of which sugars',
            protein: 'Protein',
            salt: 'Sodium',
        },
        recycle: 'Recycle',
        warning: 'Warnings',
        certification: 'Certification',
        production_info: 'Production info',
        operator_type: {
            producer: 'Produttore',
            bottler: 'Imbottigliatore',
            importer: 'Importatore',
        },
        legal_details: 'Legal details'
    }
}