export const LANDING_TRANSLATIONS_EN = {
    "landing": {
        "hero": {
            "title": "Digital labels for your wine",
            "subtitle": "Simple, precise and <span class=\"font-bold italic\">free forever*</span>",
            "description": "Meet legal obligations without additional costs and time waste. Thanks to our platform you can create all the digital labels you need in just a few moments",
            "cta": "Start now"
        },
        "banner": {
            "text1": "DON'T WASTE TIME!",
            "text2": "Offer valid only for the first 100 subscribers!"
        },
        "features": {
            "title": "Why our digital labels are different",
            "cards": {
                "easy": {
                    "number": "1",
                    "title": "Easy",
                    "description": "Create a label in a few moments thanks to the fastest interface on the market"
                },
                "personal": {
                    "number": "2",
                    "title": "Personal",
                    "description": "Choose what to show with your colors and your brand, make your customer's experience unique"
                },
                "connected": {
                    "number": "3",
                    "title": "Connected",
                    "description": "Track your batches with a single QR, bottle multiple times without reprinting the labels"
                }
            },
            "freeForever": {
                "title": "Free forever",
                "subtitle": "Keep your labels up to date and the platform will be free forever",
                "disclaimer": "(read the conditions in the FAQ section)"
            }
        },
        "buildLabel": {
            "title": "Build your label",
            "subtitle": "in a few steps",
            "description": "Finally, with just a few clicks you can build a complete label. Select ingredients and materials from our libraries, avoiding tedious manual entries and create your templates to speed up future labels",
            "cards": {
                "recycle": "Recycling",
                "nutritional": "Nutritional values",
                "ingredients": "Ingredients",
                "grapes": "Blend"
            }
        },
        "customize": {
            "title": "Customize your labels",
            "subtitle": "easily",
            "description": "Make your brand recognizable and offer your customers a unique experience. Our service allows you to customize not only the QRs but also the style of your landing page"
        },
        "printCosts": {
            "title": "Reduce printing costs,<br />speed up times and <br />get your labels right away",
            "description": "Print only one label for the whole year. <br>Use a single QR even for batches with different ingredients or characteristics. Our solution allows you to reduce printing costs"
        },
        "scanQr": {
            "title1": "<span class=\"text-white text-4xl font-extrabold\">Scan the </span><span class=\"text-ruby text-4xl font-extrabold\">QR <br /> </span><span class=\"text-white text-4xl font-extrabold\"> and discover the </span><div class=\"bg-ruby -skew-x-12 inline-block px-2\">	<span class=\"text-white text-4xl font-extrabold\"> label </span></div>",
            "description": "See an example of how you can meet legal obligations and stand out from your competitors at the same time"
        },
        "avoidFines": {
            "title": "Avoid penalties",
            "description": "Keep your labels always updated to the regulations and avoid penalties from €5,000 to €50,000",
            "cta": {
                "mobile": "Start now ->",
                "desktop": "Don't waste time, start now for free ->"
            }
        },
        "features2": {
            "title": "Discover all the features of our product<br />agile and flexible</div>",
            "multilanguage": {
                "title": "Multilingual",
                "description": "Translate automatically into all European languages with Albicchiere's AI"
            },
            "calculator": {
                "title": "Automatic calculator of <br />nutritional values",
                "description": "Use the pre-calculated nutritional values or enter your laboratory data manually"
            },
            "updated": {
                "title": "Your wines<br />always up to date",
                "description": "Our platform learns to know your wines. <br> For every new vintage it helps you to fill in the wine sheets making your work easier and faster"
            },
            "additional": {
                "sharable": {
                    "title": "Sharable",
                    "description": "With one click, the end consumer immediately shares the label with other users"
                },
                "batch": {
                    "title": "Batch variation",
                    "description": "New batches produced? Decide whether to keep the same QR or create a new one"
                }
            }
        },
        "tracking": {
            "title": "We are ready for the future, start tracking your real batches",
            "subtitle": "Stay connected <br />to your batches forever,<br />all the way to the customer's home",
            "description": "Follow your bottles from your Winery to the end consumer's home thanks to Albicchiere's traceability technology. <br /> <br /> Discover what happened and where each bottle in your batch is located. Protect yourself from fraud and defects generated by third parties with the most complete traceability platform on the market"
        },
        "bottler": {
            "title": "Are you a labeler or a bottler?",
            "description": "Manage multiple brands simultaneously. Contact us to become a partner and get all the benefits of our service",
            "cta": "Schedule a call ->"
        },
        "faq": {
            "title": "FAQs",
            "subtitle": "Consult the questions",
            "tabs": {
                "why": "Why use it",
                "conditions": "Terms of use"
            },
            "why": {
                "q1": {
                    "question": "When did the EU regulation come into force?",
                    "answer": "The EU regulation came into force on December 8, 2023, so all wines produced from this date must have a digital label"
                },
                "q2": {
                    "question": "Can I update my labels?",
                    "answer": "Of course, you can update your labels whenever you want. You can change ingredients, recycling formats or QR style whenever you prefer: the change will go online immediately"
                },
                "q3": {
                    "question": "Which wines does the regulation apply to?",
                    "answer": "The regulation affects still wines, sparkling wines, frizzante wines, vin brulé and fortified wines"
                },
                "q4": {
                    "question": "How can I create the label if I don't know all the nutritional values in the table?",
                    "answer": "Our automatic calculator will help you calculate the kilocalories per 100ML and other data you just need to enter the alcohol volume, residual sugar and tartaric acid"
                },
                "q5": {
                    "question": "Can I add a batch without reprinting the label?",
                    "answer": "Yes, our service allows you to create a single QR and then redirect it to the desired batch. This way, you can create new batches and link them to the same vintage without having to print new labels"
                }
            },
            "conditions": {
                "q1": {
                    "question": "How can it be free forever?",
                    "answer": "We love to offer services to Wineries, our goal is to have wine sheets always updated, so we offer free services to those who help us maintain and align them over the years"
                },
                "q2": {
                    "question": "Can I upload all the labels I want?",
                    "answer": "The more labels you upload, the happier we are, the important thing is that the data is correct and complete. The service has a free annual data traffic limit, but don't worry because it is more than enough for all the views that a small or medium-sized winery receives. If you are a large winery or manage a large number of different products, schedule a call and we will find together the solution that best suits your needs."
                },
                "q3": {
                    "question": "What happens if I no longer use your service for new vintages?",
                    "answer": "Don't worry, you can stop using the service whenever you want. If you no longer want to use it for new vintages you can decide whether to keep the previous vintages active or not. If you decide to keep the service active the cost will be €49.90/year. Ah, you can resume the free plan whenever you want, just create the new labels of the last harvest by July 31 of the following year."
                },
                "q4": {
                    "question": "How do you use the data from my labels?",
                    "answer": "The data you enter will only be visible to users who use Albicchiere services (such as those who scan the QR code on your bottle). We are committed to ensuring that no data will be transferred or used by third parties without your authorization."
                }
            }
        },
        "final": {
            "title": "Free, forever",
            "description": "Start creating your digital labels now, finally: simple, precise and free",
            "cta": "Start now, for free ->"
        },
        "footer": {
            "copyright": "© 2024 Beexlab Srl - P.IVA IT03557430547 - <a href=\"https://albicchiere.com/privacy-policy/\"> Privacy Policy</a>"
        }
    }
}