// Dutch
export const PAGE_TRANSLATIONS_NL = {
    page: {
        lot_selector: {
            title: 'Batchcode',
            description: `Voer de batchcode in die u wilt weergeven voor deze wijn: Het batchnummer is meestal
                             te vinden aan de achterkant van uw fles. Wees voorzichtig, want elke batch kan andere 
                             ingrediënten en voedingswaarden hebben.`,
        },
        wine_color: {
            red: 'Rood',
            white: 'Wit',
            rose: 'Rosé',
        },
        wine_info: {
            color: 'Kleur',
            grapes: 'Druiven',
            region: 'Regio',
            alcohol: 'Alcoholpercentage',
            service_temp: 'Serveertemperatuur',
            size: 'Formaat',
        },
        ingredients: {
            title: 'Ingrediënten',
            text: 'Belangrijkste ingrediënten: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TYPE',
            for_quantity: 'Voor {{quantity}}ml ',
            energy: 'Energie',
            fat: 'Vet',
            saturated_fat: 'waarvan verzadigd',
            carbs: 'Koolhydraten',
            sugar_carbs: 'waarvan suikers',
            protein: 'Eiwit',
            salt: 'Natrium',
        },
        recycle: 'Recycle',
        warning: 'Waarschuwingen',
        certification: 'Certificering',
        production_info: 'Productiegegevens',
        operator_type: {
            producer: 'Producent',
            bottler: 'Bottelaar',
            importer: 'Importeur',
        },
        legal_details: 'Juridische informatie'
    }
}