// Romanian
export const PAGE_TRANSLATIONS_RO = {
    page: {
        lot_selector: {
            title: 'Cod lot',
            description: `Introduceți codul lotului pe care doriți să îl afișați pentru acest vin: Numărul lotului se găsește de obicei
                             pe spatele sticlei. Aveți grijă, deoarece fiecare lot poate avea ingrediente 
                             și valori nutriționale diferite.`,
        },
        wine_color: {
            red: 'Roșu',
            white: 'Alb',
            rose: 'Rose',
        },
        wine_info: {
            color: 'Culoare',
            grapes: 'Soiuri de struguri',
            region: 'Regiune',
            alcohol: 'Volumul de alcool',
            service_temp: 'Temperatura de servire',
            size: 'Dimensiune',
        },
        ingredients: {
            title: 'Ingrediente',
            text: 'Ingrediente principale: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TIP',
            for_quantity: 'Pentru {{quantity}}ml ',
            energy: 'Energie',
            fat: 'Grăsimi',
            saturated_fat: 'din care saturate',
            carbs: 'Carbohidrați',
            sugar_carbs: 'din care zaharuri',
            protein: 'Proteine',
            salt: 'Sodiu',
        },
        recycle: 'Reciclare',
        warning: 'Avertizări',
        certification: 'Certificare',
        production_info: 'Informații despre producție',
        operator_type: {
            producer: 'Producător',
            bottler: 'Îmbuteliator',
            importer: 'Importator',
        },
        legal_details: 'Detalii legale'
    }
}