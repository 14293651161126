import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./../pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'elabel-demo',
    loadChildren: () =>
      import('./../pages/elabel-demo/elabel-demo.module').then((m) => m.ElabelDemoModule),
  },
  {
    path: 'elabel-preview',
    loadChildren: () =>
      import('./../pages/elabel-preview/elabel-preview.module').then((m) => m.ElabelPreviewModule),
  },
  {
    path: 'elabels/404',
    loadChildren: () =>
      import('./../pages/elabel-not-found/elabel-not-found.module').then((m) => m.ElabelNotFoundModule),
  },
  {
    path: 'elabels/:elabelId',
    loadChildren: () =>
      import('./../pages/elabel/elabel.module').then((m) => m.ElabelModule),
  },
];
