// Portuguese
export const PAGE_TRANSLATIONS_PT = {
    page: {
        lot_selector: {
            title: 'Código do Lote',
            description: `Insira o código do lote que pretende exibir para este vinho: O número do lote geralmente
                             está localizado na parte de trás da sua garrafa. Tenha cuidado porque cada lote pode ter diferentes 
                             ingredientes e valores nutricionais.`,
        },
        wine_color: {
            red: 'Tinto',
            white: 'Branco',
            rose: 'Rosado',
        },
        wine_info: {
            color: 'Cor',
            grapes: 'Uvas',
            region: 'Região',
            alcohol: 'Teor Alcoólico',
            service_temp: 'Temperatura de Serviço',
            size: 'Tamanho',
        },
        ingredients: {
            title: 'Ingredientes',
            text: 'Ingredientes principais: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TIPO',
            for_quantity: 'Para {{quantity}}ml ',
            energy: 'Energia',
            fat: 'Gordura',
            saturated_fat: 'de que saturados',
            carbs: 'Hidratos de Carbono',
            sugar_carbs: 'de que açúcares',
            protein: 'Proteína',
            salt: 'Sódio',
        },
        recycle: 'Reciclar',
        warning: 'Avisos',
        certification: 'Certificação',
        production_info: 'Informação sobre a Produção',
        operator_type: {
            producer: 'Produtor',
            bottler: 'Engarrafador',
            importer: 'Importador',
        },
        legal_details: 'Detalhes Legais'
    }
}