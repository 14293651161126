// Croatian
export const PAGE_TRANSLATIONS_HR = {
    page: {
        lot_selector: {
            title: 'Šifra serije',
            description: `Unesite šifru serije koju želite prikazati za ovo vino: Broj serije se obično
                             nalazi na stražnjoj strani boce. Budite oprezni jer svaka serija može imati različite
                             sastojke i hranjive vrijednosti.`,
        },
        wine_color: {
            red: 'Crveno',
            white: 'Bijelo',
            rose: 'Ružičasto',
        },
        wine_info: {
            color: 'Boja',
            grapes: 'Sorta',
            region: 'Regija',
            alcohol: 'Volumena alkohola',
            service_temp: 'Temperatura serviranja',
            size: 'Veličina',
        },
        ingredients: {
            title: 'Sastojci',
            text: 'Glavni sastojci: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TIP',
            for_quantity: 'Za {{quantity}}ml ',
            energy: 'Energija',
            fat: 'Masti',
            saturated_fat: 'od toga zasićene',
            carbs: 'Ugljikohidrati',
            sugar_carbs: 'od toga šećeri',
            protein: 'Bjelančevine',
            salt: 'Natrij',
        },
        recycle: 'Recikliranje',
        warning: 'Upozorenja',
        certification: 'Certifikacija',
        production_info: 'Informacije o proizvodnji',
        operator_type: {
            producer: 'Proizvođač',
            bottler: 'Punilac',
            importer: 'Uvoznik',
        },
        legal_details: 'Pravne informacije'
    }
}
