// Bulgarian
export const PAGE_TRANSLATIONS_BG = {
    page: {
        lot_selector: {
            title: 'Код на партидата',
            description: `Въведете кода на партидата, който искате да покажете за това вино: Номерът на партидата обикновено
                             се намира на гърба на бутилката. Бъдете внимателни, тъй като всяка партида може да има различни
                             съставки и хранителни стойности.`,
        },
        wine_color: {
            red: 'Червено',
            white: 'Бяло',
            rose: 'Розе',
        },
        wine_info: {
            color: 'Цвят',
            grapes: 'Грозде',
            region: 'Регион',
            alcohol: 'Алкохолен обем',
            service_temp: 'Температура на сервиране',
            size: 'Размер',
        },
        ingredients: {
            title: 'Съставки',
            text: 'Основни съставки: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'ТИП',
            for_quantity: 'За {{quantity}}ml ',
            energy: 'Енергия',
            fat: 'Мазнини',
            saturated_fat: 'от които наситени',
            carbs: 'Въглехидрати',
            sugar_carbs: 'от които захари',
            protein: 'Протеин',
            salt: 'Натрий',
        },
        recycle: 'Рециклиране',
        warning: 'Предупреждения',
        certification: 'Сертификация',
        production_info: 'Информация за производството',
        operator_type: {
            producer: 'Производител',
            bottler: 'Бутилкар',
            importer: 'Вносител',
        },
        legal_details: 'Правна информация'
    }
}