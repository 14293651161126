// Finnish
export const PAGE_TRANSLATIONS_FI = {
    page: {
        lot_selector: {
            title: 'Erän koodi',
            description: `Syötä erän koodi, jonka haluat näyttää tälle viinille: Eränumero löytyy yleensä
                             pullon takaa. Ole varovainen, koska jokaisella erällä voi olla erilaiset 
                             ainesosat ja ravintoarvot.`,
        },
        wine_color: {
            red: 'Punainen',
            white: 'Valkoinen',
            rose: 'Rosé',
        },
        wine_info: {
            color: 'Väri',
            grapes: 'Rypäle',
            region: 'Alue',
            alcohol: 'Alkoholipitoisuus',
            service_temp: 'Annostelulämpötila',
            size: 'Koko',
        },
        ingredients: {
            title: 'Ainesosat',
            text: 'Pääainesosat: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TYYPPI',
            for_quantity: '{{quantity}}ml kohdalla ',
            energy: 'Energia',
            fat: 'Rasva',
            saturated_fat: 'josta tyydyttynyt',
            carbs: 'Hiilihydraatit',
            sugar_carbs: 'josta sokerit',
            protein: 'Proteiini',
            salt: 'Suola',
        },
        recycle: 'Kierrätys',
        warning: 'Varoitukset',
        certification: 'Sertifiointi',
        production_info: 'Tuotantotiedot',
        operator_type: {
            producer: 'Tuottaja',
            bottler: 'Pullottaja',
            importer: 'Maahantuoja',
        },
        legal_details: 'Lakitietoiset tiedot'
    }
}