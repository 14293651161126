import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    standalone: false
})
export class AppComponent implements OnInit {

  constructor(
    private _translate: TranslateService,
    private _titleService: Title,
  ) {
    this._translate.setDefaultLang('en');
    this._translate.use('en');

    //Set Language to browserLocale
    const browserLang = navigator.language.split('-')[0];
    this._translate.use(browserLang);
  }

  ngOnInit(): void {
    this._translate.get('app.title').subscribe(title => {
      this._titleService.setTitle(title);
    });
  }
}