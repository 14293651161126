// Polish
export const PAGE_TRANSLATIONS_PL = {
    page: {
        lot_selector: {
            title: 'Kod partii',
            description: `Wprowadź kod partii, który chcesz wyświetlić dla tego wina: Numer partii zazwyczaj
                             znajduje się na tylnej stronie butelki. Należy zachować ostrożność, ponieważ każda partia może mieć różne 
                             składniki i wartości odżywcze.`,
        },
        wine_color: {
            red: 'Czerwone',
            white: 'Białe',
            rose: 'Różowe',
        },
        wine_info: {
            color: 'Kolor',
            grapes: 'Rodzaj winogron',
            region: 'Region',
            alcohol: 'Zawartość alkoholu',
            service_temp: 'Temperatura serwowania',
            size: 'Pojemność',
        },
        ingredients: {
            title: 'Składniki',
            text: 'Główne składniki: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TYP',
            for_quantity: 'Na {{quantity}}ml ',
            energy: 'Energia',
            fat: 'Tłuszcz',
            saturated_fat: 'w tym nasycone',
            carbs: 'Węglowodany',
            sugar_carbs: 'w tym cukry',
            protein: 'Białko',
            salt: 'Sód',
        },
        recycle: 'Recykling',
        warning: 'Ostrzeżenia',
        certification: 'Certyfikacja',
        production_info: 'Informacje o produkcji',
        operator_type: {
            producer: 'Producent',
            bottler: 'Butelkujący',
            importer: 'Importer',
        },
        legal_details: 'Informacje prawne'
    }
}