// German
export const PAGE_TRANSLATIONS_DE = {
    page: {
        lot_selector: {
            title: 'Chargencode',
            description: `Geben Sie den Chargencode ein, den Sie für diesen Wein anzeigen möchten: Die Chargennummer befindet sich in der Regel auf der Rückseite der Flasche. Seien Sie vorsichtig, da jede Charge unterschiedliche Zutaten und Nährwerte haben kann.`,
        },
        wine_color: {
            red: 'Rot',
            white: 'Weiß',
            rose: 'Rosé',
        },
        wine_info: {
            color: 'Farbe',
            grapes: 'Trauben',
            region: 'Region',
            alcohol: 'Alkoholgehalt',
            service_temp: 'Serviertemperatur',
            size: 'Größe',
        },
        ingredients: {
            title: 'Zutaten',
            text: 'Hauptzutaten: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TYP',
            for_quantity: 'Für {{quantity}}ml ',
            energy: 'Energie',
            fat: 'Fett',
            saturated_fat: 'davon gesättigte Fettsäuren',
            carbs: 'Kohlenhydrate',
            sugar_carbs: 'davon Zucker',
            protein: 'Eiweiß',
            salt: 'Natrium',
        },
        recycle: 'Recycling',
        warning: 'Warnungen',
        certification: 'Zertifizierung',
        production_info: 'Produktionsinformationen',
        operator_type: {
            producer: 'Hersteller',
            bottler: 'Abfüller',
            importer: 'Importeur',
        },
        legal_details: 'Rechtliche Hinweise'
    }
}