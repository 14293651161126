// Danish
export const PAGE_TRANSLATIONS_DA = {
    page: {
        lot_selector: {
            title: 'Batchkode',
            description: `Indtast batchkoden, du vil vise for denne vin: Batchnummeret findes typisk
                             på bagsiden af din flaske. Vær forsigtig, da hver batch kan have forskellige 
                             ingredienser og ernæringsmæssige værdier.`,
        },
        wine_color: {
            red: 'Rød',
            white: 'Hvid',
            rose: 'Rosé',
        },
        wine_info: {
            color: 'Farve',
            grapes: 'Druesort',
            region: 'Region',
            alcohol: 'Alkoholvolumen',
            service_temp: 'Serveringstemperatur',
            size: 'Størrelse',
        },
        ingredients: {
            title: 'Ingredienser',
            text: 'Hovedingredienser: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TYPE',
            for_quantity: 'For {{quantity}}ml ',
            energy: 'Energi',
            fat: 'Fedt',
            saturated_fat: 'heraf mættede',
            carbs: 'Kulhydrat',
            sugar_carbs: 'heraf sukkerarter',
            protein: 'Protein',
            salt: 'Natrium',
        },
        recycle: 'Genbrug',
        warning: 'Advarsler',
        certification: 'Certificering',
        production_info: 'Produktionsoplysninger',
        operator_type: {
            producer: 'Producent',
            bottler: 'Tappning',
            importer: 'Importør',
        },
        legal_details: 'Juridiske detaljer'
    }
}
