// French
export const PAGE_TRANSLATIONS_FR = {
    page: {
        lot_selector: {
            title: 'Code de lot',
            description: `Entrez le code de lot que vous souhaitez afficher pour ce vin : Le numéro de lot se trouve généralement
                             au dos de votre bouteille. Soyez prudent car chaque lot peut avoir des 
                             ingrédients et des valeurs nutritionnelles différents.`,
        },
        wine_color: {
            red: 'Rouge',
            white: 'Blanc',
            rose: 'Rosé',
        },
        wine_info: {
            color: 'Couleur',
            grapes: 'Cépages',
            region: 'Région',
            alcohol: 'Taux d\'alcool',
            service_temp: 'Température de service',
            size: 'Taille',
        },
        ingredients: {
            title: 'Ingrédients',
            text: 'Ingrédients principaux : {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TYPE',
            for_quantity: 'Pour {{quantity}}ml ',
            energy: 'Énergie',
            fat: 'Matières grasses',
            saturated_fat: 'dont acides gras saturés',
            carbs: 'Glucides',
            sugar_carbs: 'dont sucres',
            protein: 'Protéines',
            salt: 'Sodium',
        },
        recycle: 'Recycler',
        warning: 'Avertissements',
        certification: 'Certification',
        production_info: 'Informations sur la production',
        operator_type: {
            producer: 'Producteur',
            bottler: 'Embouteilleur',
            importer: 'Importateur',
        },
        legal_details: 'Mentions légales'
    }
}