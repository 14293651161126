export const LANDING_TRANSLATIONS_IT = {
    landing: {
        hero: {
            title: 'Etichette digitali per il tuo vino',
            subtitle: 'Semplici, precise e <span class="font-bold italic">gratuite per sempre*</span>',
            description: 'Rispondi agli obblighi di legge senza costi aggiuntivi e perdite di tempo. Grazie alla nostra piattaforma puoi creare in pochi istanti tutte le etichette digitali di cui hai bisogno',
            cta: 'Inizia subito'
        },
        banner: {
            text1: 'NON PERDERE TEMPO!',
            text2: 'Offerta valida solo per i primi 100 iscritti!'
        },
        features: {
            title: 'Perché le nostre etichette digitali sono diverse',
            cards: {
                easy: {
                    number: '1',
                    title: 'Facili',
                    description: 'Crea un\'etichetta in pochi istanti grazie all\'interfaccia più veloce sul mercato'
                },
                personal: {
                    number: '2',
                    title: 'Personali',
                    description: 'Scegli cosa mostrare con i tuoi colori e con il tuo brand, rendi unica l\'esperienza del tuo cliente'
                },
                connected: {
                    number: '3',
                    title: 'Connesse',
                    description: 'Traccia i tuoi lotti con un unico QR, imbottiglia in più volte senza ristampare le etichette'
                }
            },
            freeForever: {
                title: 'Gratis per sempre',
                subtitle: 'Tieni aggiornate le tue etichette e la piattaforma sarà gratuita per sempre',
                disclaimer: '(leggi le condizioni alla sezione FAQ)'
            }
        },
        buildLabel: {
            title: 'Costruisci la tua etichetta',
            subtitle: 'in pochi step',
            description: 'Finalmente con pochi click riesci a costruire un\'etichetta completa. Seleziona gli ingredienti e i materiali nelle nostre librerie evitando noiosi inserimenti manuali e crea i tuoi template per velocizzare le prossime etichette',
            cards: {
                recycle: 'Riciclo',
                nutritional: 'Valori nutrizionali',
                ingredients: 'Ingredienti',
                grapes: 'Uvaggio'
            }
        },
        customize: {
            title: 'Personalizza le tue etichette',
            subtitle: 'facilmente',
            description: 'Rendi il tuo marchio riconoscibile e offri ai tuoi clienti un\'esperienza unica. Il nostro servizio ti permette di personalizzare non solo i QR ma anche lo stile della tua landing page'
        },
        printCosts: {
            title: 'Riduci i costi di stampa,<br />velocizza i tempi e <br />ottieni le tue etichette subito',
            description: 'Stampa una sola etichetta per tutta l\'annata. <br>Usa un solo QR anche per lotti con ingredienti o caratteristiche diverse. La nostra soluzione ti permette di ridurre i costi di stampa'
        },
        scanQr: {
            title1: '<span class="text-white text-4xl font-extrabold">Scansiona il </span><span class="text-ruby text-4xl font-extrabold">QR <br /> </span><span class="text-white text-4xl font-extrabold"> e scopri l’ </span><div class="bg-ruby -skew-x-12 inline-block px-2">	<span class="text-white text-4xl font-extrabold"> etichetta </span></div>',
            description: 'Guarda subito un esempio di come potrai rispondere agli obblighi di legge e distinguerti dai concorrenti allo stesso tempo'
        },
        avoidFines: {
            title: 'Evita le sanzioni',
            description: 'Mantieni le tue etichette sempre aggiornate alla Normativa ed evita sanzioni da €5mila fino a €50mila',
            cta: {
                mobile: 'Inizia subito ->',
                desktop: 'Non perdere tempo, inizia subito gratuitamente ->'
            }
        },
        features2: {
            title: 'Scopri tutte le funzioni del nostro prodotto<br />agile e flessibile</div>',
            multilanguage: {
                title: 'Multilingua',
                description: 'Traduci automaticamente in tutte le lingue europee con l\'AI di albicchiere'
            },
            calculator: {
                title: 'Calcolatore automatico dei<br />valori nutrizionali',
                description: 'Usa i valori nutrizionali precalcolati o inserisci manualmente i tuoi dati di laboratorio'
            },
            updated: {
                title: 'I tuoi vini<br />sempre aggiornati',
                description: 'La nostra piattaforma impara a conoscere i tuoi vini. <br> Per ogni nuova annata ti aiuta a compilare le schede vino rendendo il tuo lavoro sempre più facile e veloce'
            },
            additional: {
                sharable: {
                    title: 'Condivisibile',
                    description: 'Con un click, il consumatore finale condivide subito l\'etichetta con altri utenti'
                },
                batch: {
                    title: 'Variante di lotto',
                    description: 'Nuovi lotti prodotti? Decidi se mantenere lo stesso QR o crearne uno nuovo'
                }
            }
        },
        tracking: {
            title: 'Siamo pronti per il futuro, inizia a tracciare i tuoi lotti reali',
            subtitle: 'Resta connesso <br />ai tuoi lotti per sempre,<br />fino a casa del cliente',
            description: 'Segui le tue bottiglie dalla tua Cantina fino alla casa del consumatore finale grazie alla tecnologia di tracciabilità di Albicchiere. <br /> <br /> Scopri cosa è successo e dove si trova ogni singola bottiglia del tuo lotto. Tutelati da frodi e difetti generati da terzi con la piattaforma di tracciabilità più completa del mercato'
        },
        bottler: {
            title: 'Sei un etichettatore o un imbottigliatore?',
            description: 'Gestisci più brand contemporaneamente. Contattaci per diventare partner e ottenere tutti i vantaggi del nostro servizio',
            cta: 'Fissa una call ->'
        },
        faq: {
            title: 'FAQs',
            subtitle: 'Consulta le domande',
            tabs: {
                why: 'Perché usarla',
                conditions: 'Condizioni d\'uso'
            },
            why: {
                q1: {
                    question: 'Quando è entrato in vigore il regolamento UE?',
                    answer: 'Il regolamento UE è entrato in vigore l\'8 Dicembre 2023, perciò tutti i vini prodotti da questa data devono essere dotati di etichetta digitale'
                },
                q2: {
                    question: 'Posso aggiornare le mie etichette?',
                    answer: 'Certo, puoi aggiornare le tue etichette quando vuoi. Puoi modificare ingredienti, formati di riciclo o stile del QR quando preferisci: la modifica andrà subito online'
                },
                q3: {
                    question: 'A quali vini si applica la normativa?',
                    answer: 'La normativa ha effetto su vini fermi, spumanti, frizzanti, vin brulé e vini liquorosi'
                },
                q4: {
                    question: 'Come posso creare l\'etichetta se non conosco tutti i valori nutrizionali della tabella?',
                    answer: 'Il nostro calcolatore automatico ti aiuterà a calcolare le chilocalorie per 100ML e altri dati di infatti ti basta inserire il volume alcolico, lo zucchero residuo e l\'acido tartarico'
                },
                q5: {
                    question: 'Posso aggiungere un lotto senza ristampare l\'etichetta?',
                    answer: 'Si, il nostro servizio ti permette di creare un unico QR e poi reindirizzarlo al lotto desiderato. In questo modo, puoi creare nuovi lotti e collegarli alla stessa annata senza dover stampare nuove etichette'
                }
            },
            conditions: {
                q1: {
                    question: 'Come fa ad essere gratis per sempre?',
                    answer: 'Amiamo offrire servizi alle Cantine, il nostro obbiettivo è avere schede vino sempre aggornate, per questo offriamo servizi gratuiti a chi ci aiuta a mantenerle ed allineate negli anni'
                },
                q2: {
                    question: 'Posso caricare tutte le etichette che voglio?',
                    answer: 'Più etichette carichi e più siamo felici, l\'importante che i dati siano corretti e completi. Il servizio ha un limite annuo di traffico dati gratuito, ma non preoccuparti perchè è più che sufficiente per tutte le visualizzazioni che riceve una cantina di piccole o medie dimensioni. Se sei una grande cantina o gestisci un gran numero di prodotti diversi fissa una call e troveremo insieme la soluzione più adatta alle tue esigenze.'
                },
                q3: {
                    question: 'Cosa succede se non utilizzo più il vostro servizio per le nuove annate?',
                    answer: 'Non preoccuparti, puoi smettere di utilizzare il servizio quando vuoi. Se non vuoi più utilizzarlo per le nuove annate puoi decidere se mantenere attive le annate precedenti o meno. Nel caso decidessi di mantenere il servizio attivo il costo sarà di 49,90€/anno. Ah, puoi riprendere il piano gratuito quando vuoi, basta solo creare le nuove etichette dell\'ultima vendemmia entro il 31 luglio dell\'anno seguente.'
                },
                q4: {
                    question: 'Come utilizzate i dati delle mie etichette?',
                    answer: 'I dati da te inseriti saranno visibili solo dagli utenti che usano i servizi Albicchiere (come ad esempio chi scannerizza il QR della tua bottiglia). Ci impegniamo affinchè nessun dato sarà ceduto o utilizzato da terzi senza la tua autorizzazione.'
                }
            }
        },
        final: {
            title: 'Gratis, per sempre',
            description: 'Inizia subito a creare le tue etichette digitali, finalmente: semplici, precise e gratuite',
            cta: 'Inizia subito, gratis ->'
        },
        footer: {
            copyright: '© 2024 Beexlab Srl - P.IVA IT03557430547 - <a href="https://albicchiere.com/privacy-policy/"> Privacy Policy</a>'
        }
    }
}