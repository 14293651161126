
// Czech
export const PAGE_TRANSLATIONS_CS = {
    page: {
        lot_selector: {
            title: 'Kód šarže',
            description: `Zadejte kód šarže, který chcete zobrazit pro toto víno: Číslo šarže se obvykle
                             nachází na zadní straně láhve. Buďte opatrní, protože každá šarže může mít různé
                             ingredience a nutriční hodnoty.`,
        },
        wine_color: {
            red: 'Červené',
            white: 'Bílé',
            rose: 'Růžové',
        },
        wine_info: {
            color: 'Barva',
            grapes: 'Odrůda',
            region: 'Region',
            alcohol: 'Objem alkoholu',
            service_temp: 'Teplota servírování',
            size: 'Velikost',
        },
        ingredients: {
            title: 'Složení',
            text: 'Hlavní ingredience: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TYP',
            for_quantity: 'Na {{quantity}}ml ',
            energy: 'Energie',
            fat: 'Tuky',
            saturated_fat: 'z toho nasycené',
            carbs: 'Sacharidy',
            sugar_carbs: 'z toho cukry',
            protein: 'Bílkoviny',
            salt: 'Sůl',
        },
        recycle: 'Recyklace',
        warning: 'Upozornění',
        certification: 'Certifikace',
        production_info: 'Informace o výrobě',
        operator_type: {
            producer: 'Výrobce',
            bottler: 'Plnička',
            importer: 'Dovozce',
        },
        legal_details: 'Právní informace'
    }
}