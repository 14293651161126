import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { EN_TRANSLATIONS } from '../translations/en/index.translations';
import { IT_TRANSLATIONS } from '../translations/it/index.translations';
import { HR_TRANSLATIONS } from '../translations/hr/index.translations';
import { DA_TRANSLATIONS } from '../translations/da/index.translations';
import { ET_TRANSLATIONS } from '../translations/et/index.translations';
import { FI_TRANSLATIONS } from '../translations/fi/index.translations';
import { FR_TRANSLATIONS } from '../translations/fr/index.translations';
import { EL_TRANSLATIONS } from '../translations/el/index.translations';
import { GA_TRANSLATIONS } from '../translations/ga/index.translations';
import { LV_TRANSLATIONS } from '../translations/lv/index.translations';
import { LT_TRANSLATIONS } from '../translations/lt/index.translations';
import { MT_TRANSLATIONS } from '../translations/mt/index.translations';
import { NL_TRANSLATIONS } from '../translations/nl/index.translations';
import { PL_TRANSLATIONS } from '../translations/pl/index.translations';
import { PT_TRANSLATIONS } from '../translations/pt/index.translations';
import { RO_TRANSLATIONS } from '../translations/ro/index.translations';
import { SK_TRANSLATIONS } from '../translations/sk/index.translations';
import { SL_TRANSLATIONS } from '../translations/sl/index.translations';
import { ES_TRANSLATIONS } from '../translations/es/index.translations';
import { DE_TRANSLATIONS } from '../translations/de/index.translations';
import { SV_TRANSLATIONS } from '../translations/sv/index.translations';
import { HU_TRANSLATIONS } from '../translations/hu/index.translations';
import { CS_TRANSLATIONS } from '../translations/cs/index.translations';
import { BG_TRANSLATIONS } from '../translations/bg/index.translations';

export class AlbiTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    switch (lang) {
      case 'it':
        return of(IT_TRANSLATIONS);
      case 'bg':
        return of(BG_TRANSLATIONS);
      case 'cs':
        return of(CS_TRANSLATIONS);
      case 'hr':
        return of(HR_TRANSLATIONS);
      case 'da':
        return of(DA_TRANSLATIONS);
      case 'et':
        return of(ET_TRANSLATIONS);
      case 'fi':
        return of(FI_TRANSLATIONS);
      case 'fr':
        return of(FR_TRANSLATIONS);
      case 'el':
        return of(EL_TRANSLATIONS);
      case 'en':
        return of(EN_TRANSLATIONS);
      case 'ga':
        return of(GA_TRANSLATIONS);
      case 'it':
        return of(IT_TRANSLATIONS);
      case 'lv':
        return of(LV_TRANSLATIONS);
      case 'lt':
        return of(LT_TRANSLATIONS);
      case 'mt':
        return of(MT_TRANSLATIONS);
      case 'nl':
        return of(NL_TRANSLATIONS);
      case 'pl':
        return of(PL_TRANSLATIONS);
      case 'pt':
        return of(PT_TRANSLATIONS);
      case 'ro':
        return of(RO_TRANSLATIONS);
      case 'sk':
        return of(SK_TRANSLATIONS);
      case 'sl':
        return of(SL_TRANSLATIONS);
      case 'es':
        return of(ES_TRANSLATIONS);
      case 'de':
        return of(DE_TRANSLATIONS);
      case 'sv':
        return of(SV_TRANSLATIONS);
      case 'hu':
        return of(HU_TRANSLATIONS);
      case 'en':
      default:
        return of(EN_TRANSLATIONS);
    }
  }
}
