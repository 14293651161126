// Slovenian
export const PAGE_TRANSLATIONS_SL = {
    page: {
        lot_selector: {
            title: 'Koda serije',
            description: `Vnesite kodo serije, ki jo želite prikazati za to vino: Številka serije se običajno nahaja na hrbtni strani steklenice. Bodite previdni, saj lahko vsaka serija vsebuje različne sestavine in hranilne vrednosti.`,
        },
        wine_color: {
            red: 'Rdeče',
            white: 'Belo',
            rose: 'Rosé',
        },
        wine_info: {
            color: 'Barva',
            grapes: 'Sorta',
            region: 'Regija',
            alcohol: 'Vsebnost alkohola',
            service_temp: 'Temperatura serviranja',
            size: 'Velikost',
        },
        ingredients: {
            title: 'Sestavine',
            text: 'Glavne sestavine: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'VRSTA',
            for_quantity: 'Za {{quantity}}ml ',
            energy: 'Energija',
            fat: 'Maščobe',
            saturated_fat: 'od tega nasičene',
            carbs: 'Ogljikovi hidrati',
            sugar_carbs: 'od tega sladkorji',
            protein: 'Beljakovine',
            salt: 'Natrij',
        },
        recycle: 'Reciklaža',
        warning: 'Opozorila',
        certification: 'Certifikat',
        production_info: 'Informacije o proizvodnji',
        operator_type: {
            producer: 'Proizvajalec',
            bottler: 'Polnilec',
            importer: 'Uvoznik',
        },
        legal_details: 'Pravne informacije'
    }
}