// Spanish
export const PAGE_TRANSLATIONS_ES = {
    page: {
        lot_selector: {
            title: 'Código de lote',
            description: `Introduzca el código de lote que desea mostrar para este vino: El número de lote generalmente se encuentra en la parte posterior de la botella. Tenga cuidado porque cada lote puede tener ingredientes y valores nutricionales diferentes.`,
        },
        wine_color: {
            red: 'Tinto',
            white: 'Blanco',
            rose: 'Rosado',
        },
        wine_info: {
            color: 'Color',
            grapes: 'Uvas',
            region: 'Región',
            alcohol: 'Volumen de alcohol',
            service_temp: 'Temperatura de servicio',
            size: 'Tamaño',
        },
        ingredients: {
            title: 'Ingredientes',
            text: 'Ingredientes principales: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TIPO',
            for_quantity: 'Para {{quantity}}ml ',
            energy: 'Energía',
            fat: 'Grasa',
            saturated_fat: 'de las cuales saturadas',
            carbs: 'Hidratos de carbono',
            sugar_carbs: 'de las cuales azúcares',
            protein: 'Proteína',
            salt: 'Sodio',
        },
        recycle: 'Reciclar',
        warning: 'Advertencias',
        certification: 'Certificación',
        production_info: 'Información de producción',
        operator_type: {
            producer: 'Productor',
            bottler: 'Embotellador',
            importer: 'Importador',
        },
        legal_details: 'Detalles legales'
    }
}