// Slovak
export const PAGE_TRANSLATIONS_SK = {
    page: {
        lot_selector: {
            title: 'Kód šarže',
            description: `Zadajte kód šarže, ktorý chcete zobraziť pre toto víno: Číslo šarže sa zvyčajne nachádza na zadnej strane fľaše. Buďte opatrní, pretože každá šarža môže mať rôzne ingrediencie a nutričné hodnoty.`,
        },
        wine_color: {
            red: 'Červené',
            white: 'Biele',
            rose: 'Růžové',
        },
        wine_info: {
            color: 'Farba',
            grapes: 'Odroda',
            region: 'Región',
            alcohol: 'Objem alkoholu',
            service_temp: 'Teplota servírovania',
            size: 'Veľkosť',
        },
        ingredients: {
            title: 'Zloženie',
            text: 'Hlavné zložky: {{ingredients}} '
        },
        nutritional_facts: {
            type: 'TYP',
            for_quantity: 'Na {{quantity}}ml ',
            energy: 'Energia',
            fat: 'Tuky',
            saturated_fat: 'z toho nasýtené',
            carbs: 'Sacharidy',
            sugar_carbs: 'z toho cukry',
            protein: 'Bielkoviny',
            salt: 'Soľ',
        },
        recycle: 'Recyklácia',
        warning: 'Upozornenia',
        certification: 'Certifikácia',
        production_info: 'Informácie o výrobe',
        operator_type: {
            producer: 'Výrobca',
            bottler: 'Plnička',
            importer: 'Dovozca',
        },
        legal_details: 'Právne informácie'
    }
}