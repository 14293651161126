export const LANDING_TRANSLATIONS_ES = {
    "landing": {
        "hero": {
            "title": "Etiquetas digitales para tu vino",
            "subtitle": "Simples, precisas y <span class=\"font-bold italic\">gratis para siempre*</span>",
            "description": "Cumple con las obligaciones legales sin costos adicionales ni pérdida de tiempo. Gracias a nuestra plataforma, puedes crear en unos instantes todas las etiquetas digitales que necesitas",
            "cta": "Comienza ahora"
        },
        "banner": {
            "text1": "¡NO PIERDAS TIEMPO!",
            "text2": "¡Oferta válida solo para los primeros 100 suscriptores!"
        },
        "features": {
            "title": "Por qué nuestras etiquetas digitales son diferentes",
            "cards": {
                "easy": {
                    "number": "1",
                    "title": "Fáciles",
                    "description": "Crea una etiqueta en unos instantes gracias a la interfaz más rápida del mercado"
                },
                "personal": {
                    "number": "2",
                    "title": "Personales",
                    "description": "Elige qué mostrar con tus colores y tu marca, haz que la experiencia de tu cliente sea única"
                },
                "connected": {
                    "number": "3",
                    "title": "Conectadas",
                    "description": "Rastrea tus lotes con un solo QR, embotella varias veces sin volver a imprimir las etiquetas"
                }
            },
            "freeForever": {
                "title": "Gratis para siempre",
                "subtitle": "Mantén tus etiquetas actualizadas y la plataforma será gratuita para siempre",
                "disclaimer": "(lee las condiciones en la sección de preguntas frecuentes)"
            }
        },
        "buildLabel": {
            "title": "Crea tu etiqueta",
            "subtitle": "en unos pocos pasos",
            "description": "Finalmente, con solo unos clics, puedes crear una etiqueta completa. Selecciona los ingredientes y los materiales de nuestras bibliotecas, evitando tediosas entradas manuales, y crea tus plantillas para acelerar las próximas etiquetas",
            "cards": {
                "recycle": "Reciclaje",
                "nutritional": "Valores nutricionales",
                "ingredients": "Ingredientes",
                "grapes": "Mezcla"
            }
        },
        "customize": {
            "title": "Personaliza tus etiquetas",
            "subtitle": "fácilmente",
            "description": "Haz que tu marca sea reconocible y ofrece a tus clientes una experiencia única. Nuestro servicio te permite personalizar no solo los QR, sino también el estilo de tu página de inicio"
        },
        "printCosts": {
            "title": "Reduce los costos de impresión,<br />acelera los tiempos y <br />obtiene tus etiquetas de inmediato",
            "description": "Imprime solo una etiqueta para todo el año. <br>Utiliza un solo QR, incluso para lotes con diferentes ingredientes o características. Nuestra solución te permite reducir los costos de impresión"
        },
        "scanQr": {
            "title1": "<span class=\"text-white text-4xl font-extrabold\">Escanea el </span><span class=\"text-ruby text-4xl font-extrabold\">QR <br /> </span><span class=\"text-white text-4xl font-extrabold\"> y descubre la </span><div class=\"bg-ruby -skew-x-12 inline-block px-2\">	<span class=\"text-white text-4xl font-extrabold\"> etiqueta </span></div>",
            "description": "Mira un ejemplo de cómo puedes cumplir con las obligaciones legales y diferenciarte de tus competidores al mismo tiempo"
        },
        "avoidFines": {
            "title": "Evita las sanciones",
            "description": "Mantén tus etiquetas siempre actualizadas con la normativa y evita sanciones de 5.000 € a 50.000 €",
            "cta": {
                "mobile": "Comienza ahora ->",
                "desktop": "No pierdas tiempo, comienza ahora gratis ->"
            }
        },
        "features2": {
            "title": "Descubre todas las funciones de nuestro producto<br />ágil y flexible</div>",
            "multilanguage": {
                "title": "Multilingüe",
                "description": "Traduce automáticamente a todos los idiomas europeos con la IA de Albicchiere"
            },
            "calculator": {
                "title": "Calculadora automática de <br />valores nutricionales",
                "description": "Utiliza los valores nutricionales precalculados o ingresa manualmente tus datos de laboratorio"
            },
            "updated": {
                "title": "Tus vinos<br />siempre actualizados",
                "description": "Nuestra plataforma aprende a conocer tus vinos. <br> Para cada nueva añada, te ayuda a completar las fichas de vino, lo que hace que tu trabajo sea cada vez más fácil y rápido"
            },
            "additional": {
                "sharable": {
                    "title": "Compartible",
                    "description": "Con un clic, el consumidor final comparte inmediatamente la etiqueta con otros usuarios"
                },
                "batch": {
                    "title": "Variante de lote",
                    "description": "¿Nuevos lotes producidos? Decide si mantienes el mismo QR o creas uno nuevo"
                }
            }
        },
        "tracking": {
            "title": "Estamos listos para el futuro, comienza a rastrear tus lotes reales",
            "subtitle": "Mantente conectado <br />a tus lotes para siempre,<br />hasta la casa del cliente",
            "description": "Sigue tus botellas desde tu bodega hasta la casa del consumidor final gracias a la tecnología de trazabilidad de Albicchiere. <br /> <br /> Descubre qué sucedió y dónde se encuentra cada botella de tu lote. Protégete contra el fraude y los defectos generados por terceros con la plataforma de trazabilidad más completa del mercado"
        },
        "bottler": {
            "title": "¿Eres un etiquetador o un embotellador?",
            "description": "Gestiona varias marcas al mismo tiempo. Ponte en contacto con nosotros para convertirte en socio y obtener todos los beneficios de nuestro servicio",
            "cta": "Programa una llamada ->"
        },
        "faq": {
            "title": "Preguntas frecuentes",
            "subtitle": "Consulta las preguntas",
            "tabs": {
                "why": "Por qué usarlo",
                "conditions": "Condiciones de uso"
            },
            "why": {
                "q1": {
                    "question": "¿Cuándo entró en vigor el reglamento de la UE?",
                    "answer": "El reglamento de la UE entró en vigor el 8 de diciembre de 2023, por lo que todos los vinos producidos a partir de esta fecha deben tener una etiqueta digital"
                },
                "q2": {
                    "question": "¿Puedo actualizar mis etiquetas?",
                    "answer": "Por supuesto, puedes actualizar tus etiquetas cuando quieras. Puedes cambiar los ingredientes, los formatos de reciclaje o el estilo del QR cuando quieras: el cambio se publicará en línea inmediatamente"
                },
                "q3": {
                    "question": "¿A qué vinos se aplica la normativa?",
                    "answer": "La normativa afecta a los vinos tranquilos, espumosos, frizzantes, vinos calientes y vinos licorosos"
                },
                "q4": {
                    "question": "¿Cómo puedo crear la etiqueta si no conozco todos los valores nutricionales de la tabla?",
                    "answer": "Nuestra calculadora automática te ayudará a calcular las kilocalorías por 100 ml y otros datos, solo tienes que introducir el volumen de alcohol, el azúcar residual y el ácido tartárico"
                },
                "q5": {
                    "question": "¿Puedo agregar un lote sin volver a imprimir la etiqueta?",
                    "answer": "Sí, nuestro servicio te permite crear un solo QR y luego redirigirlo al lote deseado. De esta manera, puedes crear nuevos lotes y vincularlos a la misma añada sin tener que imprimir nuevas etiquetas"
                }
            },
            "conditions": {
                "q1": {
                    "question": "¿Cómo puede ser gratis para siempre?",
                    "answer": "Nos encanta ofrecer servicios a las bodegas, nuestro objetivo es tener fichas de vino siempre actualizadas, por eso ofrecemos servicios gratuitos a quienes nos ayudan a mantenerlas y alinearlas a lo largo de los años"
                },
                "q2": {
                    "question": "¿Puedo cargar todas las etiquetas que quiera?",
                    "answer": "Cuanto más etiquetas cargues, más felices estaremos, lo importante es que los datos sean correctos y completos. El servicio tiene un límite de tráfico de datos gratuito anual, pero no te preocupes, ya que es más que suficiente para todas las visualizaciones que recibe una bodega pequeña o mediana. Si eres una bodega grande o gestionas un gran número de productos diferentes, programa una llamada y encontraremos juntos la solución que mejor se adapte a tus necesidades."
                },
                "q3": {
                    "question": "¿Qué pasa si ya no uso tu servicio para nuevas añadas?",
                    "answer": "No te preocupes, puedes dejar de usar el servicio cuando quieras. Si ya no quieres usarlo para nuevas añadas, puedes decidir si mantener las añadas anteriores activas o no. Si decides mantener el servicio activo, el costo será de € 49,90 / año. Ah, puedes volver al plan gratuito cuando quieras, solo tienes que crear las nuevas etiquetas de la última cosecha antes del 31 de julio del año siguiente."
                },
                "q4": {
                    "question": "¿Cómo usas los datos de mis etiquetas?",
                    "answer": "Los datos que ingreses solo serán visibles para los usuarios que utilizan los servicios de Albicchiere (como aquellos que escanean el código QR de tu botella). Nos comprometemos a garantizar que ningún dato se transfiera o utilice por terceros sin tu autorización."
                }
            }
        },
        "final": {
            "title": "Gratis, para siempre",
            "description": "Comienza ahora a crear tus etiquetas digitales, finalmente: simples, precisas y gratuitas",
            "cta": "Comienza ahora, gratis ->"
        },
        "footer": {
            "copyright": "© 2024 Beexlab Srl - P.IVA IT03557430547 - <a href=\"https://albicchiere.com/privacy-policy/\"> Política de privacidad</a>"
        }
    }
}